/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from 'react';
import {BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer, Zoom } from "react-toastify";
import AuthRoutes from "./AuthRoutes";
import PrivateRoutes from "./PrivateRoutes";
import ContentLoader from "../components/Loader";
import history from '../helpers/history';

const Routes = props => {
    const LoginAdmin = lazy(() => import('../pages/Auth/LoginAdmin'));
    const CreateAdmin = lazy(() => import('../pages/Auth/CreateAdmin'));
    const ForgotPasswordPage = lazy(() => import('../pages/Auth/ForgotPassword.js'));
    const CreatePasswordPage = lazy(() => import('../pages/Auth/CreatePassword.js'));
    const Dashboard = lazy(() => import('../pages/Dashboard'));
    const Admin = lazy(() => import("../pages/Admin"));
    const Users = lazy(() => import("../pages/Users"));
    const Waitlist = lazy(() => import("../pages/Waitlist"));
    const Bookies = lazy(() => import("../pages/Bookies"));
    const Predict = lazy(() => import("../pages/Predict"));
    const Games = lazy(() => import("../pages/Games"));
    const Referral = lazy(() => import("../pages/Referral"));
    const Subscriptions = lazy(() => import("../pages/Subscriptions"));
    const Banners = lazy(() => import("../pages/Banners"));
    const Settings = lazy(() => import("../pages/Settings"));
    const Reports = lazy(() => import("../pages/Reports"));
    const Leaderboards = lazy(() => import("../pages/Leaderboards"));
    const Notifications = lazy(() => import("../pages/Notifications"));
    const PaymentsBilling = lazy(() => import("../pages/Payments & Billing"));
    const AuditTrial = lazy(() => import("../pages/AuditTrail"));
    const SearchWaitlist = lazy(() => import("../components/SearchWaitlist"));
    const Health = lazy(() => import("../pages/Health"));

  return (
    <>
      <BrowserRouter history={history}>
        <ToastContainer
          autoClose={5000}
          transition={Zoom}
          toastClassName="dark-toast"
        />
        <Suspense fallback={<ContentLoader type="TailSpin" color="#ccc" h="45" w="45" style={{ margin: "15rem auto", width: "5%",}}/>} >
          <Switch>
            <AuthRoutes exact path="/" component={LoginAdmin} />
            <AuthRoutes exact path="/complete-profile" component={CreateAdmin} />
            <AuthRoutes exact path="/create-password" component={CreatePasswordPage} />

            <AuthRoutes
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <PrivateRoutes exact path="/dashboard" component={Dashboard} />
            <PrivateRoutes exact path="/admin" component={Admin} />
            <PrivateRoutes exact path="/users" component={Users} />
            <PrivateRoutes exact path="/waitlist-users" component={Waitlist} />
            <PrivateRoutes exact path="/subscriptions" component={Subscriptions} />
            <PrivateRoutes exact path="/bookies" component={Bookies} />
            <PrivateRoutes exact path="/predict" component={Predict} />
            <PrivateRoutes exact path="/games" component={Games} />
            <PrivateRoutes exact path="/referrals" component={Referral} />
            <PrivateRoutes exact path="/banners" component={Banners} />
            <PrivateRoutes exact path="/settings" component={Settings} />
            <PrivateRoutes exact path="/reports" component={Reports} />
            <PrivateRoutes exact path="/leaderboards" component={Leaderboards} />
            <PrivateRoutes exact path="/notifications" component={Notifications} />
            <PrivateRoutes exact path="/payments" component={PaymentsBilling} />
            <PrivateRoutes exact path="/audit" component={AuditTrial} />
            <PrivateRoutes exact path="/search-predictions" component={SearchWaitlist} />
            <PrivateRoutes exact path="/health" component={Health} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
};


export default Routes;