import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";
   
const INITIAL_STATE = {
  bookings: {},
  community: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "FETCH_BOOKING_ANALYTICS":
        return {
            ...state,
            bookings: action.payload,
        }
      case "FETCH_COMMUNITY_ANALYTICS":
        return {
            ...state,
            community: action.payload,
        }
      case ENV_CHANGE:
      case LOGOUT_USER:
        return INITIAL_STATE
      default:
        return state;
    }
};
