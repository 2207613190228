import { combineReducers } from "redux";
import auth from "./authReducer";
import loading from "./loadingReducer";
import subscriptions from "./subscriptionsReducer"
import projectUpdate from "./projectUpdateReducer";
import users from "./userReducer";
import admins from "./adminReducer";
import bookies from "./bookieReducer";
import predict from "./predictReducer";
import leaderboard from "./leaderboardReducer"
import agentMonitoring from './agentMonitoringReducer';
import notifications from "./notificationsReducer";
import others from "./othersReducer";
import transfers from "./transfersReducer";
import analytics from "./analyticsReducer";
import {LOGOUT_USER} from "../types/authTypes"

export const appReducer = combineReducers({
  auth,
  loading,
  subscriptions,
  projectUpdate,
  agentMonitoring,
  users,
  admins,
  bookies,
  predict,
  leaderboard,
  notifications,
  others,
  transfers,
  analytics,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    localStorage.clear();
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;